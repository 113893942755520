import './App.css';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import WelcomePage from './pages/WelcomePage';
import HomePage from './pages/HomePage';
import ProjectDetailPage from './pages/ProjectDetailPage';
import Contact from './pages/Contact';
import AdminLoginPage from './pages/AdminLoginPage';
import AdminManagePage from './pages/AdminManagePage';
import { AuthProvider } from './auth/AuthContext';
import AdminSideBar from './components/AdminSideBar';
import AdminProjectPage from './pages/AdminProjectPage';
import AdminProjectEditPage from './pages/AdminProjectEditPage';
import NotFoundPage from './pages/NotFoundPage';
import AdminNotificationsPage from './pages/AdminNotificationsPage';
import DragAndDropExample from './components/DragAndDrop';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/ex" element={<DragAndDropExample />} />
          <Route path="/admin/*" element={<AppAdmin />} />
          <Route path="/not-found" element={<NotFoundPage />} />
          <Route path="/*" element={<AppWithNavBar />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

const AppWithNavBar = () => {
  return (
    <>
      <NavBar />
      <div id="page-body">
        <Routes>
          <Route path="/projects" element={<HomePage />} />
          <Route path="/project/:projectId" element={<ProjectDetailPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<Navigate to="/not-found" />} />
        </Routes>
      </div>
    </>
  );
};

const AppAdmin = () => {
  const location = useLocation();
  const showAdminSideBar = location.pathname !== '/admin' && location.pathname !== '/admin/';

  return (
    <>
      <AuthProvider>
          <div id="admin-wrapper">
            {showAdminSideBar && <AdminSideBar />}
            <Routes>
              <Route path="/" element={<AdminLoginPage />} />
              <Route path="/manage" element={<AdminManagePage />} />
              <Route path="/notifications" element={<AdminNotificationsPage />} />
              <Route path="/project" element={<AdminProjectPage />} />
              <Route path="/project/:projectId" element={<AdminProjectEditPage />} />
              <Route path="/*" element={<Navigate to="/not-found" />} />
            </Routes>
          </div>
      </AuthProvider>
    </>
  );
};

export default App;
