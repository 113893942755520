import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useProtectedRoute from '../auth/useProtectedRoute';
import DragAndDropExample from '../components/DragAndDrop';

const AdminManagePage = () => {
  console.log('AdminManage'); 
  useProtectedRoute(); 


  const navigate = useNavigate();
  const [ allProjectsInfo, setAllProjectsInfo ] = useState([]);
  const [ orderData, setOrderData] = useState([]);
  const basePath = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const loadAllProjectsInfo = async () => {
      try {       
        const response = await fetch(`${basePath}api/projects`, {method: 'GET'});
        if (response.status === 404) {
          console.log('404 error');
        }
        const jsonRes = await response.json();
        setAllProjectsInfo(jsonRes.data);
        const orderData = jsonRes.data?.map(p => ({ id: p.id, content: p.title}));
        setOrderData(orderData);
      } catch (error) {
        console.error('Error:', error);
      }   
    };
    loadAllProjectsInfo();
  },[basePath]);

  const navToCreate = () => {
    navigate('/admin/project');
  }

  return (
    <>
    <div className="manage-wrapper">
      <div className="manage-projects">
        <h2>Admin Manage</h2>
        <div className="project-list">
          {allProjectsInfo?.map((project) => (
            <div key={project.id} className="project-item">
              <Link to={`/admin/project/${project.id}`} className="project-link">
                <span className="project-text">Edit Project {project.title}</span>
              </Link>
            </div>
          ))}
        </div>
        <div className="create-project-btn-wrapper">
            <button className="create-project-btn" onClick={navToCreate}>Create new Project</button>
          </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <DragAndDropExample data={orderData}/>
      </div>
    </div>
    </>
  );
};

export default AdminManagePage;
