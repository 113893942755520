import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import mainImageDefault from '../MainImageDefault.png';

const HomePage = () => {
  const pictureFileNames = [];
  const [ allProjectsInfo, setAllProjectsInfo ] = useState([]);
  const navigate = useNavigate();
  const basePath = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const loadAllProjectsInfo = async () => {
      try {
        const response = await fetch(`${basePath}api/projects`, {method: 'GET'});
        if (response.status === 404) {
          console.log('404 error');
          navigate('/not-found');
        }
        const jsonRes = await response.json();
        setAllProjectsInfo(jsonRes.data);
      } catch (error) {
        console.error('Error:', error);
        navigate('/not-found');
      }   
    };
    loadAllProjectsInfo();
  },[basePath, navigate]);

  for (let i = 1; i <= 12; i++) {
    const paddedIndex = i.toString().padStart(2, '0');
    const fileName = `pic${paddedIndex}.jpg`;
    pictureFileNames.push(fileName);
  }

  return (
    <>
      <div className="home_page_images">
        {allProjectsInfo?.map((project) => (
          <div key={project.id} className="grayscale-image">
            <Link to={`/project/${project.id}`}>
              <img
                src={project.mainPictureLoc ? basePath + "api/" + project.mainPictureLoc : mainImageDefault}
                alt={`Slide ${project.id}`}
                style={{ width: '210px', height: '320px' }}
              />
              <div className="image-title">{project.title}</div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default HomePage;
