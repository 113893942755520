import React from "react";
import notfoundImage from '../404NotFound.png';

const NotFoundPage = () => {
  return (
    <div style={{
        textAlign: "center",
        background: "#f7f7f7",
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "860px", height: "511px", margin: "0 auto", marginBottom: "20px",
        }}
      >
        <img src={notfoundImage} alt="not found" style={{ width: "100%", height: "100%" }}/>
      </div>
      <a
        style={{
          textDecoration: "none", color: "#007bff", fontSize: "18px", fontWeight: "bold", background: "#f7f7f7", cursor: "pointer"
        }}
        href="/projects"
      >
        Go Home
      </a>
    </div>
  );
};

export default NotFoundPage;
