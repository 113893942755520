import React, { useRef } from 'react';
import { useNavigate  } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const commentRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('name', firstNameRef.current.value, lastNameRef.current.value);
    console.log('email', emailRef.current.value);

    const basePath = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${basePath}api/contact-info`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: emailRef.current.value,
          firstName: firstNameRef.current.value,
          lastName: lastNameRef.current.value,
          comment: commentRef.current.value
        }),
      });
      if (response.ok) {
        const data = await response.json();
        toast.success('Form submitted successfully');
        await new Promise((resolve) => setTimeout(resolve, 3000));
      } else toast.error('An error occurred');
    } catch (error) {
      toast.error('An error occurred');
    }

    navigate('/contact');
  }

  return (
    <>
      <h4>Contact Form</h4>
      <p>Vui Lòng để lại cho chúng tôi lời nhắn cùng email hoặc số điện thoại</p>
      <div className="form-wrapper">
        <div className="form-left">
          <div className="form-name">
            {/* <label htmlFor="firstName">Name</label> */}
            <p className="form-label">Name</p>
            <input
              id="firstName"
              type="text"
              placeholder="First"
              ref={firstNameRef}
            />
            <input
              id="lastName"
              type="text"
              placeholder="Last"
              ref={lastNameRef}
            />
          </div>
          <div className="form-email">
            <p className="form-label">Email</p>
            <input
              id="email"
              type="email"
              placeholder="Email Address"
              ref={emailRef}
            />
          </div>
        </div>
        <div className="form-right">
          <p className="form-label">Comment</p>
          <textarea
            id="comment"
            rows = {6}
            ref={commentRef}
          />
        </div>
      </div>
      <div>
        <button type="submit" className="submit-contact-btn" onClick={handleSubmit}>Submit</button>
      </div>
      <ToastContainer position="bottom-right" autoClose={3000} />
    </>
  );
};

export default ContactForm;


