import React from 'react';
import { Link } from 'react-router-dom';

const AdminSideBar = () => {
  return (
    <div className="admin-sidebar">
      <ul>
        <li>
          <img src={process.env.PUBLIC_URL + '/images/home.png'} style={{height: '16px', marginRight: '4px'}} ></img>
          <Link to="/projects">Home</Link>
        </li>
        <li>
          <img src={process.env.PUBLIC_URL + '/images/gears.png'} style={{height: '16px', marginRight: '4px'}} ></img>
          <Link to="/admin/manage">Projects</Link>
        </li>
        <li>
          <img src={process.env.PUBLIC_URL + '/images/chat.png'} style={{height: '16px', marginRight: '4px'}} ></img>
          <Link to="/admin/notifications">Notifications</Link>
        </li>
        <li>
          <img src={process.env.PUBLIC_URL + '/images/door.png'} style={{height: '16px', marginRight: '4px'}} ></img>
          <Link to="/admin/manage">LogOut</Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminSideBar;
