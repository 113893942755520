import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const WelcomePage = () => {
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  }; 

  return (
    <div id="welcome-page" className={`welcome-page ${hovered ? 'hovered' : ''}`}>
      {/* <div className="click-area"> */}
        <Link 
          to="/projects" className="click-area"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >  
        </Link>
      {/* </div> */}
    </div>
  );
};

export default WelcomePage;