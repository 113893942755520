import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const DragAndDropExample = ({data}) => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const basePath = process.env.REACT_APP_API_URL;
  useEffect(() => {
    setItems([...data]);
  }, [data]);
  
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, movedItem);

    setItems(updatedItems);
  };

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#c2d7e7" : "#c2d7e7",
    padding: '110px 50px 50px 50px',
    width: 400,
    margin: "auto",
    borderRadius: 10
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: 16,
    margin: `0 0 8px 0`,
    borderRadius: '10px',
    fontSize: 'medium',
    display: 'flex',
    fontWeight: 'bold',
    cursor: 'move',
  
    background: isDragging ? "#d9d3d3" : "#fff",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    const projectsOrder = items.map((i, index) => ({
      ...i, 
      order: index + 1
    }));

    console.log(projectsOrder);
    try {
      const response = await fetch(`${basePath}api/admin/projects/order`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(projectsOrder),
      });
      if (response.ok) {
        const data = await response.json();
        toast.success('Update the order of projects successfully');
        await new Promise((resolve) => setTimeout(resolve, 3000));
      } else toast.error('An error occurred');
    } catch (error) {
      toast.error('An error occurred');
    }

    navigate('/admin/manage');
  }

  return (
    <>
    <div style={{ display: 'flex', width: 400, zIndex: 1, alignItems: 'center' }}>
      <h3 >Sort the order of projects</h3>
      <button className='btn-update-order' onClick={handleUpdateSubmit}>Update</button>
    </div>
    <div style={{ border: 'solid 1px white', width: '500px', marginBottom: '-90px', zIndex:1 }}></div>
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            //style={{ display: 'flex', flexWrap: 'wrap' }}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.length > 0 && items.map((item, index) => (
              <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    // style={{
                    //   userSelect: 'none',
                    //   padding: '10px',
                    //   margin: '0 2px 8px 0',
                    //   backgroundColor: 'white',
                    //   border: '1px solid lightgrey',
                    //   borderRadius: '2px',
                    //   width: '200px',
                    //   ...provided.draggableProps.style,
                    // }}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <img src={process.env.PUBLIC_URL + '/images/drag.png'}></img>
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
};

export default DragAndDropExample;
