import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const MapGoogleApi = () => {
  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  };

  const center = {
    lat: 12.694271,
    lng: 108.045066
  };
  //account ttthanh@gmail.com AIzaSyApIW554KMKxVYq_Be_iX33cgolbXSr-Wc
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyApIW554KMKxVYq_Be_iX33cgolbXSr-Wc',
  });

  const markerOptions = {
    position: center,
    icon: {
      url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    },
  };

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={17}>
      {/* <Marker {...markerOptions} /> */}
      <Marker position={center} />
    </GoogleMap>
  );
};

export default MapGoogleApi;
