import ContactForm from "../components/ContactForm";
import MapGoogleApi from "../components/MapGoogleApi";

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="contact-detail-wrapper">
        <div>
          <h4>Introduction</h4>
          <p>7A Architecture Studio là một văn phòng kiến trúc nhỏ được thành lập từ năm 2016
            tại thành phố Buôn Ma Thuột bởi KTS Nguyễn Hoàng Dũng <br/>
            Tại đây chúng tôi tập trung vào nghiên cứu và thực hành các dự án có quy mô từ
            nhỏ tới trung bình cũng như luôn tìm kiếm cơ hội cho những dự án có quy mô lớn hơn.
          </p>
        </div>
        <div>
          <h4>Contact</h4>
          <p>
            Email: contact@7astudio.vn <br/>
            Phone: +84 0935 577 566 <br/>
            Instagram: 7a.archstudio <br/>
            Address: số nhà 7A đường Nguyễn Đình Chiểu nối dài, phường Tân Lợi, thành phố Buôn Ma Thuột, tỉnh Đăk Lăk
          </p>
        </div>
        <ContactForm />
      </div>
      <div className="contact-map-wrapper">
        <MapGoogleApi />
      </div>
      
    </div>
  );
};

export default Contact;
