import React, { useState, useEffect } from 'react';
import useProtectedRoute from '../auth/useProtectedRoute';

const AdminNotificationsPage = () => {
  useProtectedRoute();

  const [ contactInfoList, setContactInfoList ] = useState([]);
  const basePath = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    const loadContactInfoList = async () => {
      try {
        const response = await fetch(`${basePath}api/admin/contact-info`, {method: 'GET'});
        if (response.status === 404) {
          console.log('404 error');
        }
        const jsonRes = await response.json();
        console.log('Lists: ', jsonRes);
        setContactInfoList(jsonRes.data);
      } catch (error) {
        console.error('Error:', error);
      }   
    };
    loadContactInfoList();
  },[basePath]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    return new Date(dateString).toLocaleString('en-US', options);
  }

  return (
    <table className="contact-table">
      <thead>
        <tr>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Comment</th>
          <th>TimeStamp</th>
        </tr>
      </thead>
      <tbody>
        {contactInfoList?.map((contact, index) => (
          <tr key={index}>
            <td>{contact.email}</td>
            <td>{contact.firstName}</td>
            <td>{contact.lastName}</td>
            <td>{contact.comment}</td>
            <td>{formatDate(contact.dateCreated)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AdminNotificationsPage;
