import { Link, useLocation  } from "react-router-dom";

const NavBar = () => {
  const location = useLocation();

  return (
    <nav id="horizontal-menu-wrapper">
      <div className="logo">
        <Link to="/" className="click-area"></Link>
      </div>
      <ul className="horizontal-menu">
        <li className={location.pathname === "/projects" || location.pathname.startsWith("/project/") ? "active" : ""}>
          <Link to="/projects">projects</Link>
        </li>
        {/* <li className={location.pathname.startsWith("/project") ? "active" : ""}>
          <Link to="/project/1">Project</Link>
        </li> */}
        <li className={location.pathname === "/contact" ? "active" : ""}>
          <Link to="/contact">profiles</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;