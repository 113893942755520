import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import defaultImg from '../DefaultImage.png';

const ProjectDetailPage = () => {
  const pictureFileNames = [];
  const [defaultImage, setDefaultImage] = useState(null);
  const { projectId } = useParams();
  const [ projectInfo, setProjectInfo ] = useState({});
  const navigate = useNavigate();
  const basePath = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const loadProjectInfo = async () => {
      try {
        console.log(process.env);
        const response = await fetch(`${basePath}api/project/${projectId}`, {method: 'GET'});
        if (response.status === 404) {
          console.log('404 error');
          navigate('/not-found');
        }
        const jsonRes = await response.json();
        console.log('response: ', jsonRes.data);
        setProjectInfo(jsonRes.data);
        console.log('location', jsonRes.data.pictureData?.[0].location);
        setDefaultImage(jsonRes.data.pictureData?.[0].location)
      } catch (error) {
        console.error('Error:', error);
        navigate('/not-found');
      }   
    };
    loadProjectInfo();
  },[basePath, navigate, projectId]);

  for (let i = 1; i <= 12; i++) {
    const paddedIndex = i.toString().padStart(2, '0');
    const fileName = `pic${paddedIndex}.jpg`;
    pictureFileNames.push(fileName);
  }

  const maxSlidesToShow = 12;
  const slidesToShow = Math.min(maxSlidesToShow, projectInfo.pictureData?.length || 1);

  const slideSettings = {
    dots: true,
    // infinite: true,
    slidesToShow: slidesToShow, 
    slidesToScroll: 2,
    arrows: true, // Show arrow navigation
    swipeToSlide: true, // Enable swiping on touch devices
  };

  return (
    <div className="project-detail-page">
      <div className="project-details">
        <h1>{projectInfo?.title}</h1>
        {/* <p dangerouslySetInnerHTML={{ __html: sampleProjectDetail.content }}></p> */}
        <p className="label"><span className="header">Đơn vị thực hiện: </span>{projectInfo?.implementedBy}</p>
        <p className="label"><span className="header">Kiến trúc sư: </span>{projectInfo.architect}</p>
        <p className="label"><span className="header">Năm hoàn thành: </span>{projectInfo.completedYear}</p>
        <p className="label"><span className="header">Tổng diện tích sàn: </span>{projectInfo.totalArea}</p>
        <p className="label"><span className="header">Địa điểm: </span>{projectInfo.location}</p>
        <p className="label"><span className="header">Thi công: </span>{projectInfo.constructedBy}</p>
        <div className="project-details-body">
          <p>{projectInfo.description}</p>
        </div>
      </div>
      <div className="project-image">
        <img
            src={defaultImage ? `${basePath + "api/" + defaultImage}` : defaultImg}
            alt={`Slide first`}
            // style={{ height: '500px' }}
          />
      </div>
      <div className="project-all-images">
        <Slider {...slideSettings}>
          {projectInfo.pictureData?.map((picData, index) => (
            <div key={index} className="thumbnail-container">
              <img
                key={index}
                src={basePath + "api/" + picData.location}
                alt={`Pic ${index + 1}`}
                className="thumbnail-image"
                onClick = {() => setDefaultImage(picData.location)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default ProjectDetailPage;