import React, { useRef, useState } from 'react';
import useProtectedRoute from '../auth/useProtectedRoute';

const AdminProjectPage = () => {
  useProtectedRoute();

  const titleRef = useRef(null);
  const implementedByRef = useRef(null);
  const completedYearRef = useRef(null);
  const totalAreaRef = useRef(null);
  const constructedByRef = useRef(null);
  const architectRef = useRef(null);
  const locationRef = useRef(null);
  const descriptionRef = useRef(null);
  const [images, setImages] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('title', titleRef.current.value);
    formData.append('implementedBy', implementedByRef.current.value);
    formData.append('architect', architectRef.current.value);
    formData.append('completedYear', completedYearRef.current.value);
    formData.append('totalArea', totalAreaRef.current.value);
    formData.append('location', locationRef.current.value);
    formData.append('constructedBy', constructedByRef.current.value);
    formData.append('description', descriptionRef.current.value);
    Object.values(images).forEach(img => {
      formData.append('images', img);
    });

    const basePath = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${basePath}api/admin/create-project`, {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Result: ', data);
      } else console.log('There is no response from server');
    } catch (error) {
      console.error('Error occurred during create project:', error);
    }
    
    // Reset form fields
    titleRef.current.value = '';
    implementedByRef.current.value = '';
    architectRef.current.value = '';
    completedYearRef.current.value = '';
    totalAreaRef.current.value = '';
    locationRef.current.value = '';
    constructedByRef.current.value = '';
    descriptionRef.current.value = '';
    setImages([]);
  };

  const handleImageUpload = (e) => {
    //const files = Array.from(e.target.files);
    const files = e.target.files;
    setImages(files);
  };

  return (
    <div className="form-container">
      <h2>Create/Edit Project</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Project Title:</label>
          <input type="text" id="title" ref={titleRef} />
        </div>
        <div>
          <label htmlFor="implementedBy">Đơn vị thực hiện:</label>
          <input type="text" id="implementedBy" ref={implementedByRef} />
        </div>
        <div>
          <label htmlFor="architecture">Kiến trúc sư:</label>
          <input type="text" id="architecture" ref={architectRef} />
        </div>
        <div>
          <label htmlFor="completedYear">Năm hoàn thành:</label>
          <input type="text" id="completedYear" ref={completedYearRef} />
        </div>
        <div>
          <label htmlFor="totalArea">Tổng diện tích sàn:</label>
          <input type="text" id="totalArea" ref={totalAreaRef} />
        </div>
        <div>
          <label htmlFor="location">Địa điểm:</label>
          <input type="text" id="location" ref={locationRef} />
        </div>
        <div>
          <label htmlFor="constructedBy">Thi công:</label>
          <input type="text" id="constructedBy" ref={constructedByRef}/>
        </div>
        <div>
          <label htmlFor="description">Thông tin chi tiết:</label>
          <textarea id="description" rows={10} maxrows={20} ref={descriptionRef}></textarea>
        </div>
        <div>
          <label htmlFor="images">Hình ảnh dự án:</label>
          <input type="file" id="images" multiple onChange={handleImageUpload} className="custom-file-input"/>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AdminProjectPage;
