import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate  } from "react-router-dom";
import useProtectedRoute from '../auth/useProtectedRoute';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminProjectEditPage = () => {
  useProtectedRoute();
  console.log('Page renders');
  const navigate = useNavigate();
  const titleRef = useRef(null);
  const implementedByRef = useRef(null);
  const completedYearRef = useRef(null);
  const totalAreaRef = useRef(null);
  const constructedByRef = useRef(null);
  const architectRef = useRef(null);
  const locationRef = useRef(null);
  const descriptionRef = useRef(null);
  const [images, setImages] = useState([]);
  const [mainPicId, setMainPicId] = useState(0);
  let currentMainPicId;

  const { projectId } = useParams();
  const [ projectInfo, setProjectInfo ] = useState({});
  const [ checkedDeletePic, setCheckedDeletePic ] = useState([]);
  const basePath = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    const loadProjectInfo = async () => {
      const response = await fetch(`${basePath}api/project/${projectId}`, {method: 'GET'});
      const jsonRes = await response.json();
      console.log('response: ', jsonRes.data);
      setProjectInfo(jsonRes.data);
      const mainPic = jsonRes.data.pictureData?.find(p => p.isMain === 1);
      if (mainPic) {
        setMainPicId(mainPic.id);
        currentMainPicId = mainPic.id;
      }
    };
    loadProjectInfo();
  }, [basePath, projectId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("title", titleRef.current.value);
    formData.append("implementedBy", implementedByRef.current.value);
    formData.append("architect", architectRef.current.value);
    formData.append("completedYear", completedYearRef.current.value);
    formData.append("totalArea", totalAreaRef.current.value);
    formData.append("location", locationRef.current.value);
    formData.append("constructedBy", constructedByRef.current.value);
    formData.append("description", descriptionRef.current.value);
    Object.values(images).forEach((img) => {
      formData.append("images", img);
    });  
    formData.append("mainPicId", mainPicId);
    formData.append("currentMainPicId", currentMainPicId);
    formData.append("deletedPicList", checkedDeletePic);

    try {
      const response = await fetch(`${basePath}api/admin/project/${projectId}`, {
        method: 'PUT',
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Result: ', data);
        toast.success('Project edited successfully');
        await new Promise((resolve) => setTimeout(resolve, 3000));
      } else toast.error('An error occurred');
    } catch (error) {
      console.error('Error occurred during update project:', error);
      toast.error('An error occurred');
    }

    navToManage();
  };

  const handleImageUpload = (e) => {
    //const files = Array.from(e.target.files);
    const files = e.target.files;
    setImages(files);
  };

  const handleDelete = (picId) => {
    if (!checkedDeletePic.includes(picId)) {
      setCheckedDeletePic(prevState => [...prevState, picId]);
    } else {
      setCheckedDeletePic(prevState => prevState.filter(item => item !== picId));
    }
  };

  const navToManage = () => {
    navigate('/admin/manage');
  }

  const handleMainPicture = (id) => {
    setMainPicId(id);
  }

  return (
    <>
    <div className="project-edit-container">
      <h2>Edit Project</h2>
      <div className="content">
        <div className="left-outer">
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="title">Project Title:</label>
              <input type="text" id="title" ref={titleRef} defaultValue={projectInfo.title}/>
            </div>
            <div>
              <label htmlFor="implementedBy">Đơn vị thực hiện:</label>
              <input type="text" id="implementedBy" ref={implementedByRef} defaultValue={projectInfo.implementedBy}/>
            </div>
            <div>
              <label htmlFor="architecture">Kiến trúc sư:</label>
              <input type="text" id="architecture" ref={architectRef} defaultValue={projectInfo.architect}/>
            </div>
            <div>
              <label htmlFor="completedYear">Năm hoàn thành:</label>
              <input type="text" id="completedYear" ref={completedYearRef} defaultValue={projectInfo.completedYear}/>
            </div>
            <div>
              <label htmlFor="totalArea">Tổng diện tích sàn:</label>
              <input type="text" id="totalArea" ref={totalAreaRef} defaultValue={projectInfo.totalArea}/>
            </div>
            <div>
              <label htmlFor="location">Địa điểm:</label>
              <input type="text" id="location" ref={locationRef} defaultValue={projectInfo.location}/>
            </div>
            <div>
              <label htmlFor="constructedBy">Thi công:</label>
              <input type="text" id="constructedBy" ref={constructedByRef} defaultValue={projectInfo.constructedBy}/>
            </div>
            <div>
              <label htmlFor="description">Thông tin chi tiết:</label>
              <textarea
                id="description"
                rows={10}
                maxrows={20}
                ref={descriptionRef}
                defaultValue={projectInfo.description}
              ></textarea>
            </div>
            <div>
              <label htmlFor="images">Upload thêm ảnh mới:</label>
              <input
                type="file"
                id="images"
                multiple
                onChange={handleImageUpload}
                className="custom-file-input"
              />
            </div>
          </form>
        </div>
        <div className="right-outer">
          <label htmlFor="existed-images">Hình ảnh dự án:</label>
          <div id="existed-images">
            {projectInfo.pictureData?.map(picData => (
              <div key={picData.id} className={`image-container  ${checkedDeletePic.includes(picData.id) ? 'delete-checked' : ''}`}>
                <img 
                  src={basePath + "api/" + picData.location} 
                  style={{width: '100px', height: '100px'}} alt='' 
                  className={picData.id === mainPicId ? 'main-picture' : ''}
                  onClick={() => handleMainPicture(picData.id)}
                />
                <button className="delete-button" onClick={() => handleDelete(picData.id)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox={checkedDeletePic.includes(picData.id) ? '0 0 32 32' : '0 0 16 16'}>
                    <path
                      d={checkedDeletePic.includes(picData.id)
                        ? 'M12.98 9.073V6.817L.876 13.807l12.106 6.99v-2.422c3.286-.002 9.053.28 9.053 2.27c0 2.78-6.023 4.262-6.023 4.262v2.132s13.53.462 13.53-9.824c0-8.082-11.588-8.385-16.56-8.143z'
                        : 'M9.646 8l3.177-3.177a.5.5 0 0 0-.707-.707L8.939 7 5.762 3.823a.5.5 0 1 0-.707.707L8.232 8l-3.177 3.177a.5.5 0 0 0 .707.707L8.939 9l3.177 3.177a.5.5 0 0 0 .707-.707L9.646 8z'
                      }
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="edit-project-btn-wrapper">
          <button type="submit" className="edit-project-btn" onClick={handleSubmit}>Update</button>
          <button className="cancel-project-btn" onClick={navToManage}>Cancel</button>
        </div>
      </div>
    </div>
    <ToastContainer position="bottom-left" autoClose={3000} />
    </>
  );
};

export default AdminProjectEditPage;
